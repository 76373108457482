/**
Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021
**/
/**
File Name: featureToggleMixin.js
Description: This mixin is useful for handling the feature visibility functionality for a component
*/
import { isFeatureAvailable as toggleFeature } from "@/middleware/featureToggleManager";
import { mapGetters } from "vuex";
const featureToggleMixin = {
  data: function() {
    return {};
  },
  computed: {
    ...mapGetters("user", {
      getUserProfile: "getUserProfile"
    })
  },
  directives: {
    "ftgm__feature-enabled": {
      inserted: function(el, binding, vnode, oldVnode) {
        const canShow = toggleFeature(
          binding.value.featureAllowedUsers,
          binding.value.currUser
        );
        if (!canShow) {
          el.style.display = "none";
        }
      }
    }
  },
  methods: {
    ftgm__isFeatureAvailable(feature) {
      const allowedUsers = feature.featureAllowedUsers;
      return toggleFeature(allowedUsers, this.getUserProfile);
    },
    ftgm__filterObjFeatures(featuresObj) {
      return Object.keys(featuresObj).reduce(
        (accAvailableFeatures, featureKey) => {
          const feature = featuresObj[featureKey];
          if (this.ftgm__isFeatureAvailable(featuresObj[featureKey])) {
            accAvailableFeatures[featureKey] = feature;
          }
          return accAvailableFeatures;
        },
        {}
      );
    },
    ftgm__isUserHasFeature(featureKey) {
      return (
        this.getUserProfile.features[featureKey] !== undefined &&
        this.getUserProfile.features[featureKey]
      );
    }
  }
};
export default featureToggleMixin;
