<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondStStatisticsTab.vue
Description: This file contains UI components displaying ShrimpTalk Statistics shrimptalk response tab of ponds page in overview section
-->
<template>
  <el-row class="pond-st-statistics-tab">
    <layout-toolbar>
      <er-date-picker
        v-model="date"
        type="daterange"
        unlink-panels
        size="mini"
        :format="upm__getFormatDateString"
        :value-format="dateValueFormat"
        popper-class="st-statistics-picker"
        :start-placeholder="$t('Comm_Start')"
        :end-placeholder="$t('Comn_end')"
        :timeZoneString="getUserTimeZoneString"
        :availableInterval="availableInterval"
        :disableDateMethod="disableDateMethod"
        :clearable="false"
        @change="handleDateValueChange"
      ></er-date-picker>
      <div class="filler"></div>
      <el-select
        v-model="checkboxValue"
        @change="handleDeviceTypeChange('devices')"
        :placeholder="$t('Comn_select')"
      >
        <el-option
          v-for="item in checkboxOptions"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="dataToDisplay"
        clearable
        @change="handleDeviceTypeChange('dataToDisplay')"
        :placeholder="$t('Comn_select')"
      >
        <el-option
          v-for="item in dataToDisplayOptions"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <!-- <el-checkbox
        class="check-noise-percentage"
        size="mini"
        :label="$t('noise_percentage')"
        border
        v-model="showNoisePercent"
      ></el-checkbox> -->
      <er-legend :legendItems="getLegendItems">
        <template v-slot:legend-title="{ item }">
          {{ item.title }}
        </template>
      </er-legend>
      <er-input
        inputType="search"
        v-model="searchST"
        size="mini"
        suffix-icon="el-icon-search"
        :placeholder="$t('Comn_search')"
        @change="handelSearchChange"
      ></er-input>
    </layout-toolbar>
    <el-row class="pond-st-statistics-tab-body">
      <er-card v-loading="loading">
        <el-row type="flex" justify="center">
          <el-col v-if="tableUpdatedData.length > 0">
            <er-data-tables
              ref="table"
              :columnValue="columnValueData"
              uniqueId="pond-st-statistics"
              :tableData="tableUpdatedData"
              :actionColumn="false"
              :columns="columns"
              :elTableProps="tableProps"
              :filters="tableFilters"
              :sortMethodForColumns="sortMethodForColumns"
              type="white-header-table"
              :key="$i18n.locale"
              :searchOnTableData="searchST"
            >
              <template v-slot:title="{ row }">
                <el-row type="flex">
                  <el-tooltip
                    :content="
                      getDeviceLastCommunicationColorString(
                        row.data.comm_status
                      ).title
                    "
                    popperClass="st-settings-table-el-tooltip__popper"
                    effect="light"
                    placement="top"
                  >
                    <i
                      class="material-icons-round"
                      :style="
                        `color:${
                          getDeviceLastCommunicationColorString(
                            row.data.comm_status
                          ).color
                        }`
                      "
                      >signal_cellular_alt</i
                    >
                  </el-tooltip>
                  <span style="margin-left: 5px">
                    <er-highlight-search-component
                      :highlightStrings="row.data.highlightStrings"
                      :individualRowData="row.data"
                      :keyName="getDeviceNameUnits"
                      :withoutHighlightStrings="
                        getPreferredUnits.device_name === 'alias_name'
                          ? row.data.title
                          : row.data.code
                      "
                    />
                  </span>
                </el-row>
              </template>
              <template v-slot:datacell_header="{ header }">
                <div class="header-row-cell">{{ header.column.label }}</div>
                <span>
                  <i
                    class="el-icon-plus"
                    @click="toggleHourlyColumns($event, header.column.property)"
                  ></i>
                </span>
              </template>
              <template v-slot:datacell="{ row }">
                <div
                  class="value-holder"
                  :style="tableCellClass(row.data[row.path])"
                >
                  <span>
                    {{
                      ftm__formatEmpty(
                        ftm__digitPrecision(
                          (
                            row.data[row.path] || {
                              response_quality: undefined
                            }
                          ).response_quality,
                          1
                        )
                      )
                    }}
                  </span>
                  <span v-show="dataToDisplay === 'noisePercent'">
                    <!-- showNoisePercent -->
                    /
                    {{
                      ftm__formatEmpty(
                        ftm__digitPrecision(
                          (
                            row.data[row.path] || {
                              response_quality: undefined
                            }
                          ).noise_percentage,
                          1
                        )
                      )
                    }}
                  </span>
                  <span v-show="dataToDisplay === 'dispensedFeed'">
                    <!-- showNoisePercent -->
                    /
                    {{
                      ftm__formatEmpty(
                        ftm__digitPrecision(
                          (
                            row.data[row.path] || {
                              response_quality: undefined
                            }
                          ).dispensed_feed,
                          1
                        )
                      )
                    }}
                  </span>
                </div>
              </template>
              <template v-slot:datacell_hourly="{ row }">
                <div
                  class="value-holder"
                  :style="tableCellClass(row.childColumnsData[row.path])"
                >
                  <span>
                    {{
                      ftm__formatEmpty(
                        ftm__digitPrecision(
                          (
                            row.childColumnsData[row.path] || {
                              response_quality: undefined
                            }
                          ).response_quality,
                          1
                        )
                      )
                    }}
                  </span>
                  <span v-show="dataToDisplay === 'dispensedFeed'">
                    <!-- showNoisePercent -->
                    /
                    {{
                      ftm__formatEmpty(
                        ftm__digitPrecision(
                          (
                            row.childColumnsData[row.path] || {
                              response_quality: undefined
                            }
                          ).dispensed_feed,
                          1
                        )
                      )
                    }}
                  </span>
                </div>
              </template>
              <template
                v-for="hourColumn in getHourlyColumns"
                v-slot:[`dataCell_${hourColumn}`]="{ row }"
              >
                <div
                  :key="hourColumn"
                  class="value-holder"
                  :style="tableCellClass(row.data[hourColumn])"
                >
                </div>
              </template>
            </er-data-tables>
          </el-col>
          <template v-else>
            <er-empty class="ST-response"></er-empty>
          </template>
        </el-row>
        <el-row class="abbr-row">
            <h6>
              {{ $t('St_response_note_zero') }}
            </h6>
          </el-row>
      </er-card>
    </el-row>
  </el-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
// import moment from "moment";
export default {
  mixins: [
    errorHandlerMixin,
    filtersMixin,
    datesHandlerMixin,
    userPreferenceMixin
  ],
  data: function() {
    return {
      date: [],
      dateValueFormat: "yyyy-MM-dd",
      searchST: "",
      tableData: [],
      hourlyData: {},
      expandedDates: [],
      loading: false,
      params: {
        from_date: "",
        to_date: "",
        device_log_type: ""
      },
      hourlyDataParams: {
        from_date: "",
        to_date: "",
        device_log_type: ""
      },
      checkboxOptions: [
        {
          value: "allDevice",
          label: "all-devices"
        },
        {
          value: "activeDevice",
          label: "active-devices"
        }
      ],
      dataToDisplayOptions: [
        {
          value: "noisePercent",
          label: "noise_percentage"
        },
        {
          value: "dispensedFeed",
          label: "dispensed_Feed"
        }
      ],
      checkboxValue: "activeDevice",
      dataToDisplay: "",
      showNoisePercent: false,
      tableFilters: [
        {
          prop: ["code", "title"],
          value: "",
          filterFn: (row, filter) => {
            return filter.prop.some((prop) => {
              return row[prop]
                .toLowerCase()
                .includes(filter.value.toLowerCase());
            });
          }
        }
      ],
      dateWiseSummary: {},
      stDetails: [],
      columnValue: "STHourlyData",
      childColumnsData: {},
      previousDateEvent: {},
      previousDate: ''
    };
  },
  watch: {
    getCultureDateRange: async function(newValue, oldValue) {
      if (
        !newValue ||
        (this.getCurrentCulture && this.getCurrentCulture.harvest_id === 0)
      ) {
        return;
      }
      const values = newValue.map((x) =>
        this.dhm__dateUtilsLib.formatDate(
          this.dhm__dateUtilsLib.parse(
            x,
            this.dhm__dateUtilsLib.isoFormatString,
            new Date()
          ),
          this.dateValueFormat
        )
      );
      this.$set(this.date, 0, values[0]);
      this.$set(this.date, 1, values[1]);
      await this.handleValueChange(values);
    }
  },
  computed: {
    ...mapGetters("pondDetails", {
      getCurrentCulture: "getCurrentCulture",
      pondId: "getSelectedPondId",
      getStIdStDetailsOfSelectedPondId: "getStIdStDetailsOfSelectedPondId",
      getSelectedSTResponseType: "getSelectedSTResponseType"
    }),
    ...mapGetters("user", {
      getPreferredUnits: "getPreferredUnits"
    }),
    availableInterval() {
      return {
        start: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.start_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        ),
        end: this.dhm__dateUtilsLib.parse(
          this.getCurrentCulture.end_date,
          this.dhm__dateUtilsLib.isoFormatString,
          new Date()
        )
      };
    },
    // New computed property to get hourly column keys
    getHourlyColumns() {
      return [];
    },
    tableUpdatedData() {
      return this.tableData;
    },
    getDeviceNameUnits() {
      return this.getPreferredUnits.device_name === "alias_name"
        ? "title"
        : "code";
    },
        columnValueData() {
      return this.columnValue;
    },
    columns() {
      const dateRange = this.date;
      const newColumns = {
        title: {
          prop: this.upm__getPrefDeviceIdentityKey,
          label: this.$t("Comn_shrimptalk"),
          sortable: true,
          sortMethod: (a, b) => {
            return this.$commonUtils.alphaNumericComparator(
              this.upm__getValue(a),
              this.upm__getValue(b)
            );
          },
          fixed: true,
          minWidth: "150px",
          enableSearch: true,
          searchProperty:
            this.getPreferredUnits.device_name === "alias_name"
              ? "title"
              : "code"
        }
      };
      const pdateRange = [dateRange[0], dateRange[1]].map((x) =>
        this.dhm__dateUtilsLib.parse(
          x,
          this.dateValueFormat,
          this.dhm__dateUtilsLib.getCurrDate()
        )
      );
      const dateRangeDiff = this.dhm__dateUtilsLib.differenceInDays(
        pdateRange[1],
        pdateRange[0]
      );
      for (var i = 0; i <= dateRangeDiff; i++) {
        const dateObj = this.dhm__dateUtilsLib.parse(
          dateRange[1],
          this.dateValueFormat,
          this.dhm__getTodayInUserTZ
        );
        const utcDate = this.dhm__dateUtilsLib.zonedTimeToUtc(
          this.dhm__dateUtilsLib.subtract(dateObj, { days: i }),
          this.getUserTimeZoneString
        );
        const colValue = this.dhm__dateUtilsLib.formatUTCISOToUserUTCISO(
          utcDate,
          this.getUserTimeZoneString
        );
        const formatString = this.dhm__formatByTZOnUTCWithLocale(
          colValue,
          this.upm__getFormatDateString
        );
        newColumns[colValue] = {
          prop: colValue,
          slot: "dataCell",
          label: formatString,
          minWidth: "100px",
          showChildColumns: false
        };
      }
      return newColumns;
    },
    getArrSts() {
      return Object.values(this.getStIdStDetailsOfSelectedPondId).sort(
        (a, b) => {
          return this.$commonUtils.alphaNumericComparator(
            this.upm__getValue(a),
            this.upm__getValue(b)
          );
        }
      );
    },
    getStCodeToStDetails() {
      return this.getArrSts.reduce((acc, st) => {
        acc[st.hex_code] = st;
        return acc;
      }, {});
    },
    getLegendItems() {
      return [
        {
          title: `> 70%`,
          legendType: "CIRCLE",
          color: "#03a500"
        },
        {
          title: `>= 55%`,
          legendType: "CIRCLE",
          color: "#FFFF00"
        },
        {
          title: ">= 40%",
          legendType: "CIRCLE",
          color: "#FFA500"
        },
        {
          title: "< 40%",
          legendType: "CIRCLE",
          color: "#FF0000"
        }
      ];
    },
    tableProps: function() {
      return {
        size: "mini",
        showSummary: true,
        summaryMethod: this.getSummaries,
        defaultSort: {
          prop: this.upm__getPrefDeviceIdentityKey,
          order: "ascending"
        },
        hasNestedColumn: true,
        height: "calc(100vh - var(--height-gutter))"
      };
    },
    sortMethodForColumns: function() {
      return {
        title: this.$commonUtils.alphaNumericComparator
      };
    },
    getCultureDateRange() {
      return [
        this.getCurrentCulture.start_date,
        this.getCurrentCulture.end_date
      ];
    },
    getDateObjArrOfCultureDateRange() {
      const start_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[0],
        this.getUserTimeZoneString
      );
      const end_date = this.dhm__dateUtilsLib.utcToZonedTime(
        this.getCultureDateRange[1],
        this.getUserTimeZoneString
      );
      return [start_date, end_date];
    },
    pickerOptions() {
      return {
        disabledDate: (time) => {
          const endDate = this.dhm__dateUtilsLib.endOfDay(
            this.dhm__getTodayInUserTZ
          );
          const startDate = this.dhm__dateUtilsLib.startOfDay(
            this.dhm__dateUtilsLib.subtract(endDate, {
              days: this.getCurrentCulture.doc
            })
          );
          const currTime = time;
          const paramObj = {
            dateRange: [startDate, endDate],
            date: currTime,
            timeZone: this.getUserTimeZoneString,
            actionsOnDate: [],
            actionsOnDateRangeItem: []
          };
          return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
        }
      };
    }
  },
  async mounted() {
    this.upm__setDataKeys("code", "title");
    if (this.getCurrentCulture.harvest_id === 0) {
      this.initDateRange();
      await this.initComponent(true);
    }
  },
  methods: {
    ...mapMutations("pondDetails", {
      responseTypeValue: "ST_SELECTED_RESPONSE_TYPE"
    }),
    ...mapActions("pondDetails", {
      fetchStResponseQualityData: "fetchStResponseQualityData",
      fetchDeviceDetailsDetailsByDate: "fetchDeviceDetailsDetailsByDate",
      fetchHourlyDataUsingStore: "fetchHourlyDataUsingStore"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    handelSearchChange() {
      if (this.searchST !== "") {
        this.mixPanelEventGenerator({
          eventName: "Ponds - ShrimpTalk Response - Search"
        });
      }
    },
    async fetchHourlyData(date) {
      this.hourlyData = {};
      const response = await this.fetchHourlyDataUsingStore({
        params: this.hourlyDataParams
      });

     await response.hourly_response_quality.forEach((deviceData) => {
        const deviceCode = deviceData.code;
        this.hourlyData[deviceCode] = {};

        deviceData.hourly_response.forEach((hourlyEntry) => {
          const dateHour = `${deviceData.date} ${hourlyEntry.s_time}`;
          this.hourlyData[deviceCode][dateHour] = hourlyEntry;
        });
      });
      const tableDataCopy = this.$lodash.cloneDeep(this.tableData);
      await tableDataCopy.forEach(async (tableDataValue) => {
        const deviceCode = tableDataValue.deviceCode;

        // Check if deviceCode exists in hourlyData
        if (deviceCode in this.hourlyData) {
          // Assuming hourly_response is an object, update its property
          tableDataValue[
            this.hourlyDataParams.from_date
          ].hourly_response = await this.hourlyData[deviceCode];
          await this.generateChildColumns(this.hourlyData[deviceCode]);
        } else {
          tableDataValue[
            this.hourlyDataParams.from_date
          ].hourly_response = {};
          this.childColumnsData = {};
        }
      });
      this.tableData = tableDataCopy;
      return tableDataCopy;
    },
    generateChildColumns(hourlyColumnData) {
      this.childColumnsData = {};
        Object.keys(hourlyColumnData).forEach((key) => {
          this.childColumnsData[key] = {
            prop: key,
            slot: "dataCell_hourly",
            label: hourlyColumnData[key].s_time,
            minWidth: "80px"
          };
        });
    },
    formatApiParams(clickedDateString) {
      // Split the date string at the "T"
      const [datePart, timePart] = clickedDateString.split("T");
      console.log(timePart);
      // Construct the modified to_date string
      const toDateString = `${datePart}T23:59:59.999Z`;
      this.hourlyDataParams.from_date = clickedDateString;
      this.hourlyDataParams.to_date = toDateString;
      this.hourlyDataParams.device_log_type = this.params.device_log_type;
    },
    async toggleHourlyColumns(event, value) {
      this.loading = true;
      try {
          const date = this.columns[value].prop;
          let response = [];
          // Format API parameters
          this.formatApiParams(date);
          // Fetch hourly data
          if (this.previousDate === '' || this.previousDate !== value) {
           response = await this.fetchHourlyData(date); // Assuming fetchHourlyData accepts params
          }

          if (Object.keys(this.previousDateEvent).length === 0) {
              this.previousDateEvent = event;
          }
          if (this.previousDate === '') {
            this.previousDate = value;
          }

          if (this.previousDate !== value) {
            await this.previousDateEvent.target.classList.toggle("el-icon-minus");
            await this.$set(this.columns, this.previousDate, {
              ...this.columns[this.previousDate],
              showChildColumns: false,
              childColumns: {}
            });
            this.previousDateEvent = event;
            this.previousDate = value;
          }
          if (response.length !== 0 && this.previousDate === value) {
            await this.$set(this.columns, value, {
              ...this.columns[value],
              showChildColumns: !this.columns[value].showChildColumns,
              childColumns: this.childColumnsData
            });
            // await event.target.classList.toggle("el-icon-minus");
          } else if (response.length === 0 && this.previousDate === value) {
            await this.$set(this.columns, value, {
              ...this.columns[value],
              showChildColumns: false,
              childColumns: {}
            });
            this.previousDateEvent = {};
            this.previousDate = '';
          }
          await event.target.classList.toggle("el-icon-minus");
          // Update column visibility and icon
          this.columnValue =
            this.columnValue !== "" && this.columnValue === value ? "" : value;
          // const dataTablesRef = this.$refs.table.$refs.dataTables.$refs.elTable;
          await this.updateTableLayout();
          // Find the index of the column with the specified key
          const columnIndex = Object.keys(this.columns).findIndex(column => column === value);
          // If the column is found, scroll to it
          if (columnIndex !== -1 && columnIndex !== 1) {
            const columnHeader = await this.$refs.table.$refs.dataTables.$refs.elTable.$el.querySelectorAll('.el-table__header-wrapper th')[columnIndex - 2];
            if (columnHeader) {
              this.$refs.table.$refs.dataTables.$refs.elTable.$el.querySelector('.el-table__body-wrapper').scrollLeft = columnHeader.offsetLeft;
              await columnHeader.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
            // }
          }
      } catch (error) {
        this.ehm__errorMessages(error);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    updateTableLayout() {
      this.$nextTick(() => {
        if (this.$refs.table) {
          this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
        }
      });
    },
    disableDateMethod: function(time) {
      const currDate = time;
      const paramObj = {
        dateRange: this.getDateObjArrOfCultureDateRange,
        date: currDate,
        timeZone: this.getUserTimeZoneString,
        actionsOnDate: [],
        actionsOnDateRangeItem: []
      };
      return !this.dhm__dateUtilsLib.queryInDateRange(paramObj);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      if (columns.length === 0) {
        return [];
      }
      if (data.length === 0) {
        const columnsCnt = columns.length;
        return [
          `${this.$tc("Comn_pond", 1)} ${this.$t("Comn_response_quality")}`,
          ...new Array(columnsCnt - 1).fill("--")
        ];
      }
      const roundOff = (value) => {
        if (typeof value === "boolean") {
          return "";
        }
        return value !== undefined && +value.toFixed(1);
      };
      columns.reduce((acc, column, index) => {
        let value;
        if (column.property === this.getDeviceNameUnits) {
          value = `${this.$tc("Comn_pond", 1)} ${this.$t(
            "Comn_response_quality"
          )}`;
          acc.push(value);
          return acc;
        } else {
          if ((column.property).split(" ").length === 1) {
            const getPropertyValues = (property) => {
              return (
                this.dateWiseSummary[column.property] !== undefined &&
                this.dateWiseSummary[column.property][property]
              );
            };
            // const stData = getPropertyValues("response_quality");
            let noisePercentage;
            let dispensedFeed;
            // noOfSts = stData.length;
            value = getPropertyValues("response_quality");
            if (this.dataToDisplay === "noisePercent") {
              // showNoisePercent
              // const noiseData = getPropertyValues("noise_percentage");
              noisePercentage = getPropertyValues("noise_percentage");
              acc.push(
                [value, noisePercentage]
                  .map((x) => (!isNaN(+x) ? roundOff(x) : "--"))
                  .join(" / ")
              );
              return acc;
            }
            if (this.dataToDisplay === "dispensedFeed") {
              // const noiseData = getPropertyValues("noise_percentage");
              dispensedFeed = getPropertyValues("dispensed_feed");
              acc.push(
                [value, dispensedFeed]
                  .map((x) => (!isNaN(+x) ? roundOff(x) : "--"))
                  .join(" / ")
              );
              return acc;
            }
          }
        }
        acc.push(!isNaN(+value) ? roundOff(value) : "--");
        return acc;
      }, sums);
      return sums;
    },
    tableCellClass(row) {
      if (!row) return;
      if (row?.response_quality === null || row?.response_quality === undefined) return;
      if (row?.response_quality > 70) {
        return `--color:${this.getLegendItems[0].color}a0`;
      }
      if (row?.response_quality >= 55) {
        return `--color:${this.getLegendItems[1].color}ff`;
      }
      if (row?.response_quality >= 40) {
        return `--color:${this.getLegendItems[2].color}ff`;
      }
      return `--color:${this.getLegendItems[3].color}a0`;
    },
    handleDateValueChange(currentSelectedValues) {
      this.handleValueChange(currentSelectedValues);
      this.mixPanelEventGenerator({
        eventName: "Ponds - ShrimpTalk Response - Date Filter"
      });
    },
    handleValueChange: async function(currentSelectedValues) {
      let tempValues = currentSelectedValues;
      tempValues = await currentSelectedValues.map((x) => {
        return this.dhm__dateUtilsLib.castBrowserDateToUserUTC(
          this.dhm__dateUtilsLib.parse(
            x,
            this.dateValueFormat,
            this.dhm__getTodayInUserTZ
          ),
          this.dhm__getUserTZStringObj
        );
      });
      // this.params.from_date = moment(tempValues[0]).format("YYYY-MM-DD");
      // this.params.to_date = moment(tempValues[1]).format("YYYY-MM-DD");
      this.params.from_date = tempValues[0].toISOString();
      this.params.to_date = tempValues[1].toISOString();
      await this.initComponent(true);
    },
    initDateRange() {
      const daysAvailable = this.getCurrentCulture.doc;
      const dateRange = this.dhm__dateUtilsLib.getDateRangeFromRefDate({
        referenceDate: this.dhm__dateUtilsLib.getCurrDate(),
        distanceFromRefDate: [
          {
            action: "subtract",
            params: [{ days: daysAvailable >= 6 ? 6 : daysAvailable }]
          }
        ],
        timeZone: this.getUserTimeZoneString
      });
      this.$set(this.date, 0, dateRange[0]);
      this.$set(this.date, 1, dateRange[1]);
      this.params.from_date = dateRange[0];
      this.params.to_date = dateRange[1];
    },
    defaultRespObj() {
      return {
        total: 0,
        date: "",
        response_quality: undefined,
        noise_percentage: undefined,
        dispensed_feed: undefined,
        0: 0,
        1: 0,
        2: 0,
        3: 0
      };
    },
    defaultTableRowObj(shrimpTalkCode, columns) {
      return columns.reduce((acc, col) => {
        if (col.prop === "title" || col.prop === "code") {
          const [shrimptalk] = this.stDetails.filter(
            (st) => st.code === shrimpTalkCode
          );
          acc.title = shrimptalk.title || shrimptalk.display_code;
          acc.code = shrimptalk.display_code;
          acc.deviceCode = shrimptalk.code;
          acc.comm_status = shrimptalk.comm_status;
          return acc;
        }
        acc[col.prop] = this.defaultRespObj();
        return acc;
      }, {});
    },
    parseStResponseData(accSt, eachSt) {
      if (eachSt.length === 0) return accSt;
      let shrimpTalkCode;
      eachSt.reduce((accDay, eachDay) => {
        shrimpTalkCode = eachDay.code;
        if (!accDay[shrimpTalkCode]) {
          accDay[shrimpTalkCode] = this.defaultTableRowObj(
            shrimpTalkCode,
            Object.values(this.columns || {})
          );
        }
        const date = eachDay.date;
        if (!accDay[shrimpTalkCode][date]) {
          accDay[shrimpTalkCode][date] = this.defaultRespObj(shrimpTalkCode);
        }

        accDay[shrimpTalkCode][date].response_quality =
          eachDay.response_quality;
        accDay[shrimpTalkCode][date].noise_percentage =
          eachDay.noise_percentage;
        accDay[shrimpTalkCode][date].dispensed_feed = eachDay.dispensed_feed;
        return accDay;
      }, accSt);
      return accSt;
    },
    setLogType() {
      switch (this.checkboxValue) {
        case "allDevice":
          this.params.device_log_type = "ALL";
          break;
        case "activeDevice":
          this.params.device_log_type = "ACTIVE";
          break;
      }
    },
    async handleDeviceTypeChange(type) {
      await this.responseTypeValue(this.dataToDisplay);
      // await this.initComponent(true);
      if (type === "devices") {
        this.mixPanelEventGenerator({
          eventName: "Ponds - ShrimpTalk Response - Devices Dropdown"
        });
      } else {
        this.mixPanelEventGenerator({
          eventName: "Ponds - ShrimpTalk Response - NP - DF - Dropdown"
        });
      }
    },
    async initComponent(fetchSTsFromLog) {
      this.dataToDisplay = this.getSelectedSTResponseType;
      this.loading = true;
      this.hourlyData = {};
      this.columnValue = "STHourlyData";
      this.childColumnsData = {};
      this.previousDateEvent = {};
      this.previousDate = '';
      this.updateTableLayout();
      try {
        this.setLogType();
        const response = await this.fetchStResponseQualityData({
          params: this.params
        });
        const {
          response_quality,
          day_wise_total_response_quality,
          shrimp_talk_details
        } = response;
        this.dateWiseSummary = day_wise_total_response_quality.reduce(
          (acc, currentObj) => {
            acc[currentObj.date] = currentObj;
            return acc;
          },
          {}
        );
        const responseQuality = response_quality.reduce((acc, currentObj) => {
          if (!acc[currentObj.code]) {
            acc[currentObj.code] = [];
          }
          acc[currentObj.code].push(currentObj);
          return acc;
        }, {});
        this.stDetails = shrimp_talk_details.sort((a, b) => {
          return this.$commonUtils.alphaNumericComparator(
            this.upm__getValue(a),
            this.upm__getValue(b)
          );
        });
        const defaultRow = this.defaultTableRowObj;
        const groupDataByCode = Object.values(responseQuality).reduce(
          this.parseStResponseData,
          {}
        );
        // if (Object.keys(groupDataByCode).length === 0) {
        //   this.tableData = [];
        //   return;
        // }
        this.tableData = this.stDetails.map((x) => {
          if (groupDataByCode[x.code]) {
            return groupDataByCode[x.code];
          }
          return defaultRow(x.code, Object.values(this.columns || {}));
        });
      } catch (err) {
        this.ehm__errorMessages(err);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.table) {
            this.$refs.table.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
        this.loading = false;
      }
    },
    getDeviceLastCommunicationColorString(input) {
      const objMap = {
        ON_LINE: {
          color: "#33A02C",
          title: this.$t("online")
        },
        OFF_LINE: { color: "#E31A1C", title: this.$t("offline") }
      };
      if (input) {
        return objMap.ON_LINE;
      } else {
        return objMap.OFF_LINE;
      }
    }
  }
};
</script>

<style lang="scss">
.pond-st-statistics-tab {
  .pond-st-statistics-tab-body {
    margin-top: 8px;
  }
  // .el-range-editor--mini {
  //   @include responsiveProperty(flex-basis, 30%, 20%, 15%);
  // }
  .best-noise {
    color: #b7b7b7;
  }
  .check-noise-percentage {
    background: white;
  }
  .value-holder {
    display: inline-block;
    padding: 0px 10px;
    max-width: 100%;
    min-width: 75px;
    // width: 75px;
    background-color: var(--color, #ffffff);
    color: #000000;
    border-radius: 6px;
    text-align: center;
    font-weight: bold;
  }
  @include responsiveProperty(--height-gutter, 270px, 280px, 300px);

  .el-select {
    .selected {
      font-weight: 700;
      color: #0a2463 !important;
    }
    .el-input__inner {
      height: 28px !important;
    }
    .el-input__icon {
      line-height: 25px !important;
    }
  }
}
.el-select-dropdown__item.selected {
  font-weight: 700;
  color: #0a2463 !important;
}
.el-select-dropdown__item {
  @media only screen and (max-width: 1919px) {
    font-size: 13px;
  }
}
.ST-response {
  height: calc(100vh - 200px) !important;
}
</style>
